<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员列表</el-breadcrumb-item>
            <el-breadcrumb-item>赠送优惠券</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="会员信息">{{form.id}} - {{form.phone}} - {{form.nickName}}</el-form-item>
        <el-form-item label="优惠券">
          <el-select v-model="couponNo" filterable placeholder="请选择">
            <el-option
              v-for="item in selectList"
              :key="item.couponNo"
              :label="`${item.couponNo},${item.name}`"
              :value="item.couponNo"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getdatilRequest, editRequest } from "@/api/wxuser";
import { getallListRequest, receivecouponRequest } from "@/api/fj_coupon";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Useredit",
  data() {
    return {
      form: { id: 0 },
      couponNo: "",
      selectList: []
    };
  },
  created() {
    this.form.id = this.$route.query["id"];
    this.getallList();
    this.getdatil();
  },
  methods: {
    back() {
      this.$router.back();
    },
    getdatil() {
      getdatilRequest({
        Id: this.form.id
      }).then(res => {
        this.form = res.data;
      });
    },
    getallList(groupname) {
      getallListRequest().then(res => {
        console.log(res);
        this.selectList = res.data;
      });
    },
    save() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        receivecouponRequest({
          couponNo: this.couponNo,
          userid: this.form.id
        }).then(res => {
          if (res.code == 1) {
            this.$message(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {}
  }
};
</script>

<style lang="less" scoped>
</style>
